/* You can add global styles to this file, and also import other style files */

@import "~bs-stepper/dist/css/bs-stepper.min.css";
@import "../node_modules/angular-calendar/css/angular-calendar.css";

@font-face {
  font-family: "helveticaregular";
  src: url("assets/fonts/helvetica-01-webfont.woff2") format("woff2"),
  url("assets/fonts/helvetica-01-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "helveticabold";
  src: url("assets/fonts/helvetica-bold-02-webfont.woff2") format("woff2"),
  url("assets/fonts/helvetica-bold-02-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "helveticabold_oblique";
  src: url("assets/fonts/helvetica-boldoblique-04-webfont.woff2") format("woff2"),
  url("assets/fonts/helvetica-boldoblique-04-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "helveticaoblique";
  src: url("assets/fonts/helvetica-oblique-03-webfont.woff2") format("woff2"),
  url("assets/fonts/helvetica-oblique-03-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

.hide-content {
  margin-left: 0 !important;
}

.view-note-box {
  background: #f5f5f5;
  border-radius: 8px;
  padding: 6px;
  max-height: 250px;
  overflow: auto;
}

.btn {
  cursor: pointer;
}

.pointer {
  cursor: pointer;
}

.view-note {
  white-space: inherit;
  font-size: 0.85em;
  color: #adadad;
  padding: 1rem 0.8rem;
  display: grid;
  grid-template-columns: 40px 1fr;
  grid-gap: 0.8rem;
  align-items: center;
}

.notify-msg {
  font-size: 2em;
  display: inline-flex;
  background: #c12731;
  color: #fff;
  width: 40px;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
}

.notify-date {
  display: block;
  color: #c12731;
  font-weight: 500;
}

.view-note-box::-webkit-scrollbar {
  width: 10px;
}

/* Track */
.view-note-box::-webkit-scrollbar-track {
  background: #d3d3d3;
}

/* Handle */
.view-note-box::-webkit-scrollbar-thumb {
  background: #9e9e9e;
  border-radius: 50px;
}

/* Handle on hover */
.view-note-box::-webkit-scrollbar-thumb:hover {
  background: #555;
}

agm-map {
  height: calc(100vh - 30px);
}

/* google translation style */
#google_translate_element {
  width: 250px;
}

#google_translate_element span {
  display: none;
}

.goog-te-gadget {
  color: transparent !important;
  font-size: 0px;
}

.goog-logo-link {
  display: none !important;
}

.goog-te-banner-frame.skiptranslate {
  display: none !important;
}

.goog-te-gadget .goog-te-combo {
  margin: 4px 0;
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #f5f5f5;
  background-clip: padding-box;
  border: none;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.modal-header {
  justify-content: center !important;
  border-bottom: none !important;
}

.modal-header .close {
  padding: 0 !important;
  margin: 0 !important;
  position: absolute !important;
  right: 12px !important;
  font-size: 40px !important;
  opacity: 1 !important;
  top: 10px !important;
}

.goog-te-combo:focus {
  border: none;
  outline: none;
}

.goog-tooltip {
  display: none !important;
}

.goog-tooltip:hover {
  display: none !important;
}

.goog-text-highlight {
  background-color: transparent !important;
  border: none !important;
  box-shadow: none !important;
}

body {
  top: 0 !important;
}

/* end google translation style */
.wrapper {
  display: flex;
  width: 100%;
  align-items: stretch;
}

#content {
  width: 100%;
  height: 100%;
  transition: all 0.3s;
  margin-left: 50px;
}

.margin-left {
  margin-left: 250px !important;
}

.main-padding {
  padding: 0 40px;
}

bs-daterangepicker-container {
  left: -62px !important;
  top: 18px !important;
}

body {
  font-family: "helveticaregular" !important;
  color: #231f20 !important;
}

p {
  font-family: "helveticaregular";
  color: #231f20;
  font-size: 0.9em;
}

table {
  border: 1px solid #f5f5f5;
  border-radius: 8px;
}

table td {
  font-size: 0.9em !important;
  border-bottom: 1px solid #f5f5f5;
  border-top: none !important;
}

table th {
  font-size: 0.9em !important;
  background-color: #f5f5f5 !important;
  border-bottom: none !important;
  border-top: none !important;
}

.cm-map-box agm-map {
  height: 262px !important;
}

label {
  font-family: "helveticabold";
}

a {
  cursor: pointer;
}

.text-dan {
  color: red !important;
  font-weight: bolder;
}

::selection {
  color: #ffffff;
  background: #c12731;
}

pagination {
  float: right;
  margin-right: 10px;
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-image: url(./assets/image/drop-down-icon.svg);
  background-repeat: no-repeat;
  background-position: 97% center;
}

/* for icon */
.wrp {
  position: relative;
}

img.user-icon {
  position: absolute;
  top: 6px;
  left: 4px;
}

.wrp .form-control {
  padding-left: 31px !important;
}

.form-control::-webkit-input-placeholder {
  color: #ccc !important;
  font-size: 0.9em !important;
}

.cross {
  position: absolute;
  right: 4px;
  top: 10px;
  width: 18px;
  height: 18px;
  cursor: pointer;
}

.btn-oclm,
.btn-oclm:hover,
.btn-oclm:focus {
  background-color: #c12731 !important;
  color: #ffffff !important;
  border-radius: 8px !important;
  outline: none !important;
  box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.14), 0 7px 10px -5px #c1273191 !important;
  padding: 8px 18px !important;
  font-size: 14px !important;
}

.btn-oclm {
  position: relative !important;
  -webkit-transition-duration: 0.4s;
  /* Safari */
  transition-duration: 0.4s;
  text-decoration: none !important;
  overflow: hidden !important;
  cursor: pointer !important;
}

.btn-oclm:after {
  content: "";
  background: #f88f9685;
  display: block;
  position: absolute;
  padding-top: 300%;
  padding-left: 350%;
  margin-left: -20px !important;
  margin-top: -120%;
  opacity: 0;
  transition: all 0.9s;
}

.btn-oclm:active:after {
  padding: 0;
  margin: 0;
  opacity: 1;
  transition: 0s;
}

.btn-oclm-null,
.btn-oclm-null:focus {
  color: #d3d3d3 !important;
  background: #fff !important;
  border: 1px solid #d3d3d3 !important;
  border-radius: 8px !important;
  outline: none !important;
  padding: 8px 18px !important;
  font-size: 14px !important;
  box-shadow: none !important;
}

.page-link {
  color: #000000 !important;
}

.page-item.active .page-link {
  z-index: 3;
  color: #fff !important;
  background-color: #000000 !important;
  border-color: #000000 !important;
}

.page-select {
  display: flex;
  justify-content: flex-end;
}

.dropdown-item:active {
  background-color: #c12731 !important;
  color: #fff;
}

.btn.focus,
.btn:focus {
  outline: 0;
  box-shadow: none !important;
}

.pagination {
  margin-left: 12px;
}

bs-datepicker-container.bottom {
  /* transform: translate3d(748px, 153px, 0px) !important; */
  left: -121px !important;
  top: 12px !important;
}

.bs-datepicker-container {
  padding: 0;
}

.bs-datepicker-head button.next span,
.bs-datepicker-head button.previous span {
  line-height: 2px;
}

.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff !important;
  border-color: #dc3545 !important;
  background-color: #dc3545 !important;
}

.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: none !important;
}

.custom-control-label::before,
.custom-control-label::after {
  top: 0 !important;
}

.con-info-group {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.group-btn-last {
  display: grid;
  grid-template-columns: 108px 210px 1fr 1fr;
  grid-gap: 15px;
}

.button-group {
  display: flex;
  justify-content: space-between;
}

.w-40 {
  width: 270px;
}

.red-bg {
  background: #c3c3c3;
  color: #fff;
  opacity: 0.5;
}

.red-bg .task-time {
  background-image: url(./assets/image/open-icon-2.svg);
  background-size: contain;
}

.red-bg.red-active .task-time {
  background-image: url(./assets/image/open-icon-21.svg);
  background-size: auto;
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: center;
}

.red-active {
  box-shadow: 0 10px 20px 3px rgba(193, 39, 49);
  opacity: 1;
  background: #c12731;
}

.yellow-bg {
  background: #c3c3c3;
  color: #fff;
}

.yellow-bg .task-time {
  background-image: url(./assets/image/work-progress-icon-2.svg);
  background-size: contain;
}

.yellow-bg.yellow-active .task-time {
  background-image: url(./assets/image/work-progress-icon-21.svg);
  background-size: auto;
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: center;
}

.yellow-active {
  box-shadow: 0 10px 20px 3px rgba(234, 134, 0, 0.7);
  opacity: 1;
  background: #ea8600;
}

.blue-bg {
  background: #c3c3c3;
  color: #fff;
  opacity: 0.5;
}

.blue-bg .task-time {
  background-image: url(./assets/image/underreview-icon-2.svg);
  background-size: contain;
}

.blue-bg.blue-active .task-time {
  background-image: url(./assets/image/underreview-icon-21.svg);
  background-size: auto;
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: center;
}

.blue-active {
  box-shadow: 0 10px 20px 3px rgba(0, 124, 254, 0.7);
  opacity: 1;
  background: #007cfe;
}

.black-bg {
  background: #c3c3c3;
  color: #fff;
  opacity: 0.5;
}

.black-bg .task-time {
  background-image: url(./assets/image/schedule-icon-2.svg);
  background-size: contain;
}

.black-bg.black-active .task-time {
  background-image: url(./assets/image/schedule-icon-21.svg);
  background-size: auto;
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: center;
}

.black-active {
  box-shadow: 0 10px 20px 3px rgba(35, 31, 32, 0.7);
  opacity: 1;
  background: #231f20;
}

.green-bg {
  background: #c3c3c3;
  color: #fff;
  opacity: 0.5;
}

.green-bg .task-time {
  background-image: url(./assets/image/complete-icon-2.svg);
  background-size: contain;
}

.green-bg.green-active .task-time {
  background-image: url(./assets/image/complete-icon-21.svg);
  background-size: auto;
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: center;
}

.green-active {
  box-shadow: 0 10px 20px 3px rgba(90, 164, 83, 0.7);
  opacity: 1;
  background: #5aa453;
}

.inbox-head p {
  color: #000000 !important;
  font-weight: 700 !important;
}

.task-info-box .task-box {
  border: none !important;
}

.border-none {
  border: none;
}

.thumb-text {
  margin-bottom: 15px !important;
}

.task-time img {
  display: none;
}

.work-modal .carousel-indicators {
  display: none !important;
}

.carousel.slide:focus,
.modal-body:focus {
  outline: none;
}

.table-style-admin {
  border-collapse: separate;
  border-spacing: 0 5px;
  border-top: none;
}

.table-style-admin th {
  width: 16%;
}

.table-style-admin td,
.table-style-admin th {
  vertical-align: middle;
}

.table-style-admin td {
  font-size: inherit !important;
}

.table-style-admin td:first-child {
  font-size: 0.9em !important;
}

/* responsive start here */
@media screen and (max-width: 1366px) {
  .coustom-width-filter,
  .coustom-width,
  #google_translate_element {
    width: 13rem !important;
  }

  .drop-toggle {
    max-height: 60px !important;
  }
}

.img-show-thumb {
  display: inline-block;
}

@media screen and (max-width: 1280px) {
  .task-left-box .task-left {
    min-width: 70px;
  }

  .coustom-width-filter,
  .coustom-width,
  #google_translate_element {
    width: 10rem !important;
  }

  .img-show-thumb {
    /* width: 212px !important;
    height: 212px !important; */
    display: inline-block;
  }

  .main-img {
    /* width: 130px !important;
    height: 130px !important; */
  }

  .img-icon-thumb {
    /* width: 62px !important;
    height: 62px !important; */
  }

  .img-2 {
    /* top: 59px !important; */
  }

  .img-4 {
    /* left: 60px !important; */
  }
}

.mar-top-70 {
  margin-top: -70px;
}

.main-padding-box {
  padding: 0 40px;
}

/* for tab */
@media screen and (max-width: 800px) {
  .wrapper-login {
    grid-template-columns: repeat(auto-fit, minmax(600px, 1fr)) !important;
  }

  .coustom-width-filter,
  .coustom-width {
    width: 9rem !important;
  }

  .task-right span {
    margin: 0 15px !important;
  }

  .login-left-Mapbox {
    grid-row-start: 2;
  }

  .login-right-box {
    box-shadow: none !important;
  }

  .mob {
    display: none !important;
  }
}

@media screen and (max-width: 1024px) {
  #content {
    margin-left: 0px;
  }
}
@media screen and (max-width: 770px) {
  #content {
    margin-left: 0px;
  }

  .margin-left {
    margin-left: 0px !important;
  }

  .main-padding {
    padding: 0 0px;
  }

  #logout {
    height: auto !important;
  }

  .login-right-box {
    max-width: max-content !important;
  }
}

/*For Mobile screen */
@media screen and (max-width: 767px) {
  #content.margin-left {
    overflow-x: hidden;
    overflow-y: auto;
  }

  .mar-top-70 {
    margin-top: 0px;
  }

  .main-padding-box {
    padding: 0 0px;
  }

  .coustom-width {
    width: 100% !important;
    margin-bottom: 10px;
  }

  .page-select {
    flex-direction: column;
  }

  .wrapper-login {
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr)) !important;
    position: relative;
  }

  .geolocation-msg {
    left: 0 !important;
    top: 20% !important;
    height: fit-content !important;
  }

  .login-left-box {
    display: none;
  }

  .login-right-box {
    padding: 0 0em !important;
  }

  .otp-box .wrapper {
    justify-content: center;
  }

  .form-hight {
    height: auto !important;
    overflow-y: auto;
    overflow-x: hidden;
    padding-right: 0px;
    padding-bottom: 0px;
  }

  .login-left-Mapbox {
    grid-row-start: 2;
  }

  .login-right-box {
    box-shadow: none !important;
  }

  .task-wraper {
    height: auto !important;
    padding: 0 0 !important;
  }

  .main-padding {
    padding: 0 0px !important;
  }

  .task-list {
    grid-template-columns: 1fr !important;
  }

  .task-left-mobile {
    margin-bottom: 20px;
  }

  .group-btn-last {
    grid-template-columns: 1fr 1fr 1fr;
  }

  .grid-span {
    grid-column: 1 / 4;
  }

  .grid-span .dropdown-toggle {
    width: 100%;
  }

  .drop-toggle {
    width: 100% !important;
  }

  .coustom-width-filter {
    width: 100% !important;
    margin-bottom: 10px;
  }

  .button-group {
    padding: 0 15px;
  }

  .ma-l {
    margin-left: 0 !important;
  }

  pagination {
    margin-right: 0px;
  }

  #content {
    margin-left: 0px;
  }

  #logout {
    height: auto !important;
    width: 100vw;
  }

  .table {
    width: 965px !important;
  }
}

@media print {
  #print-wrapper * {
    visibility: visible;
  }

  .margin-left {
    margin-left: 10px !important;
  }

  #sidebar {
    display: none !important;
  }

  nav {
    display: none;
  }
}

.pac-container {
  z-index: 9999 !important;
}

.work-order-active,
.work-order-active:hover {
  border: 1px solid #adadad;
  background-color: #f3f3f3;
}

.work-order-isDue,
.work-order-isDue:hover {
  border: 1px solid #c12731;
  background-color: #c127311c;
}

.c-btn {
  background: #fff;
  border: 1px solid #ccc;
  /* color: #333; */
}

.selected-list .c-list .c-token {
  background: #0079fe;
}

.selected-list .c-list .c-token .c-label {
  color: #fff;
}

.selected-list .c-list .c-token .c-remove svg {
  fill: #fff;
}

.selected-list .c-angle-down svg,
.selected-list .c-angle-up svg {
  fill: #333;
}

.dropdown-list ul li:hover {
  background: #f5f5f5;
}

.arrow-up,
.arrow-down {
  border-bottom: 15px solid #fff;
}

.arrow-2 {
  border-bottom: 15px solid #ccc;
}

.list-area {
  border: 1px solid #ccc;
  background: #fff;
  box-shadow: 0px 1px 5px #959595;
}

.select-all {
  border-bottom: 1px solid #ccc;
}

.list-filter {
  border-bottom: 1px solid #ccc;
}

.list-filter .c-search svg {
  fill: #888;
}

.list-filter .c-clear svg {
  fill: #888;
}

.pure-checkbox input[type="checkbox"]:focus + label:before,
.pure-checkbox input[type="checkbox"]:hover + label:before {
  border-color: #0079fe;
  background-color: #f2f2f2;
}

.pure-checkbox input[type="checkbox"] + label {
  color: #000;
}

.pure-checkbox input[type="checkbox"] + label:before {
  color: #0079fe;
  border: 1px solid #0079fe;
}

.pure-checkbox input[type="checkbox"] + label:after {
  background-color: #0079fe;
}

.pure-checkbox input[type="checkbox"]:disabled + label:before {
  border-color: #ccc;
}

.pure-checkbox input[type="checkbox"]:disabled:checked + label:before {
  background-color: #ccc;
}

.pure-checkbox input[type="checkbox"] + label:after {
  border-color: #fff;
}

.pure-checkbox input[type="radio"]:checked + label:before {
  background-color: white;
}

.pure-checkbox input[type="checkbox"]:checked + label:before {
  background: #0079fe;
}

.single-select-mode .pure-checkbox input[type="checkbox"]:focus + label:before,
.single-select-mode .pure-checkbox input[type="checkbox"]:hover + label:before {
  border-color: #0079fe;
  background-color: #f2f2f2;
}

.single-select-mode .pure-checkbox input[type="checkbox"] + label {
  color: #000;
}

.single-select-mode .pure-checkbox input[type="checkbox"] + label:before {
  color: transparent !important;
  border: 0px solid #0079fe;
}

.single-select-mode .pure-checkbox input[type="checkbox"] + label:after {
  background-color: transparent !important;
}

.single-select-mode
.pure-checkbox
input[type="checkbox"]:disabled
+ label:before {
  border-color: #ccc;
}

.single-select-mode
.pure-checkbox
input[type="checkbox"]:disabled:checked
+ label:before {
  background-color: #ccc;
}

.single-select-mode .pure-checkbox input[type="checkbox"] + label:after {
  border-color: #0079fe;
}

.single-select-mode .pure-checkbox input[type="radio"]:checked + label:before {
  background-color: white;
}

.single-select-mode
.pure-checkbox
input[type="checkbox"]:checked
+ label:before {
  background: none !important;
}

.selected-item {
  background: #e9f4ff;
}

.btn-iceblue {
  background: #0079fe;
  border: 1px solid #ccc;
  color: #fff;
}

.white-col {
  color: white;
}

html, body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.date-input {
  height: 44px;
  width: 200px;
  outline: none;
  border-radius: 10px;
  font-size: 14px;
  border: 1px solid #f5f5f5;
  background-image: url(/assets/image/date-icon.svg);
  padding-left: 28px;
  cursor: pointer;
  background-position-y: 7px;
  background-position-x: 2px;
  background-repeat: no-repeat;
}

.date-input::placeholder {
  color: black;
}

ngx-daterangepicker-material > div > div.buttons > div > button {
  background: #c12731 !important;
  text-transform: capitalize !important;
  border-radius: 6px !important;
  height: 35px !important;
  width: 60px !important;
  text-align: center !important;
}

ngx-daterangepicker-material > div > div.calendar.right > div > table > tbody > tr > td.available.active.end-date.in-range,
ngx-daterangepicker-material > div > div.calendar.left > div > table > tbody > tr > td.available.active.end-date.in-range,
ngx-daterangepicker-material > div > div.calendar.left > div > table > tbody > tr > td.active.available.end-date.start-date.today,
ngx-daterangepicker-material > div > div.calendar.right > div > table > tbody > tr > td.active.available.end-date.start-date.today,
ngx-daterangepicker-material > div > div.calendar.left > div > table > tbody > tr > td.available.active.start-date,
ngx-daterangepicker-material > div > div.calendar.right > div > table > tbody > tr > td.available.active.start-date {
  background: #d9534f !important;
}

.not-clickable {
  pointer-events: none;
  cursor: default;
}

.h-96vh {
  height: 96vh;
}

.img-responsive {
  width: 210px;
  height: 122px;
}

.mr-80 {
  margin-right: 80px;
}

.height-width-24px {
  height: 24px;
  width: 24px;
}

.flex-align-center {
  display: flex;
  align-items: center;
}

.error-text {
  margin-top: 0.25rem;
  font-size: .875em;
  color: #dc3545;
}

.font-weight-600 {
  font-weight: 600;
}
.black {
  color: black;
}

::ng-deep .mat-form-field > div > div.mat-form-field-flex.ng-tns-c165-2 > div {
  border: 1px solid red !important;
  height: 100px;
  padding: 0 10px !important;
}
